
import { defineComponent, ref, onMounted, computed, reactive } from 'vue';
import {
    getPrepaymentUnQualifiedDealer,
    getPrepaymentQualifiedDealer,
} from '@/API/salesReporting';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import downloadFile from '@/utils/payment/downloadFile';

interface DeadlineRespVOListType {
    day: string;
    deadLine: string;
    payoutRound: string;
}
interface OneDataType {
    dealerCode: string;
    dealerGroup: string;
    dealerName: string;
    subRegion: string;
    [x: string]: any;
}

export default defineComponent({
    setup() {
        const router = useRouter();
        const isComplete = ref(0);
        const { state } = useStore();
        const dealerCode = ref((state.user as any).organization.entityCode);
        // dealerCode.value = '193120340';

        const deadlineRespVOList = ref<DeadlineRespVOListType[]>([]);
        const timeArray = ref<string[]>([]);

        const dataSource = ref<any[]>([]);

        const payoutRoundList = ref<string[]>([])

        const payoutRoundStat = reactive(new Map())

        const columns = ref<any>([
            {
                title: '集团',
                dataIndex: 'dealerGroup',
                key: 'dealerGroup',
                width: 200,
                align: 'center',
            },
            {
                title: '经销商名称',
                dataIndex: 'dealerName',
                key: 'dealerName',
                width: 300,
                align: 'center',
            },
            // {
            //   title: '2021 Q3',
            //   children: [
            //       {
            //           title: '折让明细',
            //           dataIndex: 'age1',
            //           key: 'age1',
            //       },
            //       {
            //           title: '红字通知单',
            //           dataIndex: 'age2',
            //           key: 'age2',
            //       }
            //   ]
            // },
            // {
            //   title: '2021 Q2',
            //   children: [
            //       {
            //           title: '折让明细',
            //           dataIndex: 'age3',
            //           key: 'age3',
            //       },
            //       {
            //           title: '红字通知单',
            //           dataIndex: 'age4',
            //           key: 'age4',
            //       }
            //   ]
            // },
        ]);

        const columnsArray = ref<any[]>([]);

        const transformationData = (data: Array<any>) => {
            // console.log(data)
            const array = [];
            for (let i = 0; i < data.length; i++) {
                const one = data[i];
                const obj: OneDataType = {
                    dealerCode: one.dealerCode,
                    dealerGroup: one.dealerGroup,
                    dealerName: one.dealerName,
                    subRegion: one.subRegion,
                };
                // for (let j=0;j<columnsArray.value.length;j++){
                //   obj[columnsArray.value[j]] = "1";
                // }

                const uploadStatus = one.uploadStatus;
                for (let j = 0; j < uploadStatus.length; j++) {
                    const payoutRound = Object.keys(uploadStatus[j])[0];

                    obj[`${payoutRound}_paymentStatementUpload`] =
                        uploadStatus[j][payoutRound][0]
                            .paymentIntroductionFileUpload === false
                            ? '未收到红票通知单'
                            : '';
                    obj[`${payoutRound}_paymentIntroductionFileUpload`] =
                        uploadStatus[j][payoutRound][0]
                            .paymentStatementUpload === false
                            ? '缺特殊折让明细'
                            : '';
                    payoutRoundStat.set(payoutRound, {
                      submissionDeadline: uploadStatus[j][payoutRound][0].submissionDeadline,
                      remainingDays: uploadStatus[j][payoutRound][0].remainingDays
                    })
                }
                array.push(obj);
            }
            dataSource.value = array;
        };

        const transformationColumns = (payoutRoundList: Array<string>) => {
            //
            for (let i = 0; i < payoutRoundList.length; i++) {
                columnsArray.value.push(
                    `${payoutRoundList[i]}_paymentIntroductionFileUpload`
                );
                columnsArray.value.push(
                    `${payoutRoundList[i]}_paymentStatementUpload`
                );
                columns.value.push({
                    slots: {
                      title: payoutRoundList[i]
                    },
                    children: [
                        {
                            title: '折让明细',
                            dataIndex: `${payoutRoundList[i]}_paymentIntroductionFileUpload`,
                            key: `${payoutRoundList[i]}_paymentIntroductionFileUpload`,
                            width: 180,
                            slots: {
                                customRender: `${payoutRoundList[i]}_paymentIntroductionFileUpload`,
                            },
                            align: 'center',
                        },
                        {
                            title: '红字通知单',
                            dataIndex: `${payoutRoundList[i]}_paymentStatementUpload`,
                            key: `${payoutRoundList[i]}_paymentStatementUpload`,
                            width: 180,
                            align: 'center',
                        },
                    ],
                });
            }
        };

        const tableHeight = ref(210);
        onMounted(() => {
            getPrepaymentQualifiedDealer({ dealerCode: dealerCode.value }).then(
                (res) => {
                    if (res) {
                        isComplete.value = 0;
                    } else {
                        getPrepaymentUnQualifiedDealer({
                            dealerCode: dealerCode.value,
                        }).then((res) => {
                            if (res.payoutRoundList.length === 0) {
                                //
                                isComplete.value = 2;
                            } else {
                                isComplete.value = 1;
                                // console.log(res)
                                deadlineRespVOList.value =
                                    res.deadlineRespVOList;
                                timeArray.value = res.payoutRoundList;
                                payoutRoundList.value = res.payoutRoundList
                                transformationColumns(res.payoutRoundList);
                                transformationData(
                                    res.programDealerDetailListRespVOS
                                );
                                // console.log(columns.value);
                                // console.log(dataSource.value)
                                // console.log(columnsArray.value)
                            }
                        });
                    }
                }
            );
        });

        // function confirmEnding(str: string, target: string) {
        //     // 请把你的代码写在这里
        //     const start = str.length-target.length;
        //     const arr = str.substr(start,target.length);
        //     if (arr == target){
        //         return true;
        //     }
        //     return false;
        // }

        const goPayment = (item: any, title: string) => {
            // console.log(item)
            console.log(title);
            if (!(title.indexOf('paymentStatementUpload') > -1)) {
                router.push({
                    path: `/DealerBreakdown`,
                });
            }
        };
        const downloadDealerFile = () => {
            const params = {
                url: `/ptapi/dealer/report/exportPrepaymentUnQualifiedDealer?dealerCode=${dealerCode.value}`,
                method: 'get',
            }
            downloadFile(params);
        }
        return {
            payoutRoundList,
            payoutRoundStat,
            goPayment,
            isComplete,
            dataSource,
            tableHeight,
            columns,
            deadlineRespVOList,
            columnsArray,
            downloadDealerFile,
        };
    },
});
